<template>
    <div id="expert-list">
        <el-form :inline="true" :model="dataForm" class="demo-form-inline">
            <el-form-item label="关键字">
                <el-input v-model="dataForm.queryString" placeholder="请输入关键字检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="行业领域">
                <el-select v-model="dataForm.talent_prossinalField" placeholder="请选择行业领域" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in prossinalFieldList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="学位">
                <el-select v-model="dataForm.talent_degree" placeholder="请选择学位" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in degreeList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序规则">
                <el-select v-model="dataForm.sortField" placeholder="排序规则" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in sortFieldList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排序方式">
                <el-select v-model="dataForm.sortValue" placeholder="排序方式" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in sortValueList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border style="width: 100%" v-loading="loading">
            <el-table-column type="index" label="序号" width="80" align="center" :index="indexMethod"></el-table-column>
            <el-table-column prop="name" label="姓名" width="100" align="center"></el-table-column>
            <el-table-column prop="sex" label="性别" width="80" align="center"></el-table-column>
            <el-table-column prop="subject" label="专业" width="200" align="center"></el-table-column>
            <el-table-column prop="degree" label="学位" width="80" align="center"></el-table-column>
            <el-table-column prop="jobTitle" label="职称" width="180" align="center"></el-table-column>
            <el-table-column prop="post" label="职务" width="180" align="center"></el-table-column>
            <el-table-column prop="unit" label="工作单位" align="center"></el-table-column>
            <el-table-column prop="school" label="毕业院校" align="center"></el-table-column>
        </el-table>
        <div class="mt-3 uf uf-ac uf-pe">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'expert-list',
    data () {
      return{
        dataForm:{
          talent_prossinalField: '',
          talent_degree: '',
          queryString: '',
          sortField: '',
          sortValue: '',
        },
        loading: false,
        data: {},
        dataList: [],
        pageNo: 1,
        pageSize: 10,
        total:10,
        prossinalFieldList:[
          {
            label: '电子信息',
            value: '电子信息'
          },
          {
            label: '生物制药',
            value: '生物制药'
          },
          {
            label: '先进材料',
            value: '先进材料'
          },
        ],
        degreeList:[
          {
            label: '学士',
            value: '学士'
          },
          {
            label: '硕士',
            value: '硕士'
          },
          {
            label: '博士',
            value: '博士'
          },
        ],
        sortFieldList:[
          {
            label:'时间',
            value: '1',
          },
          {
            label:'咨询量',
            value: '2',
          },
          {
            label:'好评率',
            value: '3',
          },
          {
            label:'综合排序',
            value: '4',
          }
        ],
        sortValueList:[
          {
            label:'升序',
            value: '1',
          },
          {
            label:'降序',
            value: '2',
          },
        ]
      }
    },
    created () {
      this.getExpertList()
    },
    methods:{
      // 查询
      onSubmit () {
        this.pageNo = 1
        this.getExpertList()
      },
      // 获取专家列表
      getExpertList () {
        this.loading = true
        this.$http({
          url: '/tecApi/api/search/talent/queryTalentsEsByPageByZXW',
          method: 'get',
          params: this.$http.adornParams({
            talent_prossinalField: this.dataForm.talent_prossinalField,
            talent_degree: this.dataForm.talent_degree,
            queryString: this.dataForm.queryString,
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            sortField: this.dataForm.sortField,
            sortValue: this.dataForm.sortValue,
          })
        }).then(({data}) => {
          this.loading = false
          if (data && data.code === 200) {
            this.data = data.data || {}
            let pageList = this.data.pageList || {}
            this.dataList = pageList.list || []
            this.total = pageList.total || 0
          }
        })
      },
      //  分页
      handleSizeChange (value) {
        this.pageSize = value
        this.getExpertList()
      },
      handleCurrentChange (value) {
        this.pageNo = value
        this.getExpertList()
      },
      indexMethod (index) {
        return this.pageSize*(this.pageNo-1) + index+1
      },
    },
  }
</script>

<style scoped>

</style>
