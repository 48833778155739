<template>
    <div id="service-info">
        <div class="zxw-nav" style="padding-left: 0px;padding-bottom: 10px">
            <div class="wrap pr">
                <div class="uf uf-pc">
                    <li class="" @click="$router.push({ name: 'serve' })">首页</li>
                    <li class="pr" @click="$router.push({ name: 'index' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">自助式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'tech-analysis' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">推荐式服务</span>
                    </li>
                    <li class="pr" @click="$router.push({ name: 'quality-doctor' })">
                        <i class="hot iconfont icon-hot"></i
                        ><span class="fwb">一站式服务</span>
                    </li>
                    <li style="list-style-type: none" @click="toStandardbzfw">
                        标准服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardqyxycx">
                        企业信用查询
                    </li>
                    <li style="list-style-type: none" @click="toStandardzcfw">
                        政策服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardzwqyml">
                        小微企业名录
                    </li>
                    <li style="list-style-type: none" @click="toStandardrzrkfw">
                        认证认可服务
                    </li>
                    <li style="list-style-type: none" @click="toStandardsbjs">
                        商标工作台
                    </li>
                    <li style="list-style-type: none" @click="toSCRM">SCRM</li>
                </div>
            </div>
        </div>
        <div  style="width: 1310px;margin: 10px auto;">
            <el-card style="min-height: 800px">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="技术转化服务" name="conversion">
                        <conversionList v-if="conversion"></conversionList>
                    </el-tab-pane>
                    <el-tab-pane label="专家服务" name="expert">
                        <expertList v-if="expert"></expertList>
                    </el-tab-pane>
                    <el-tab-pane label="仪器设备" name="equipment">
                        <equipmentList v-if="equipment"></equipmentList>
                    </el-tab-pane>
                    <el-tab-pane label="检验检测认证服务" name="test">
                        <testList v-if="test"></testList>
                    </el-tab-pane>
                    <el-tab-pane label="软件服务" name="software">
                        <softwareList v-if="software"></softwareList>
                    </el-tab-pane>
                    <el-tab-pane label="科技咨询" name="consult">
                        <consultList v-if="consult"></consultList>
                    </el-tab-pane>
                    <el-tab-pane label="研究研发" name="research">
                        <researchList v-if="research"></researchList>
                    </el-tab-pane>
                    <el-tab-pane label="服务机构" name="mechanism">
                        <mechanismList v-if="mechanism"></mechanismList>
                    </el-tab-pane>
                </el-tabs>
            </el-card>
        </div>
    </div>

</template>

<script>
  import conversionList from './conponent/conversion-list'
  import expertList from './conponent/expert-list'
  import equipmentList from './conponent/equipment-list'
  import testList from './conponent/test-list'
  import softwareList from './conponent/software-list'
  import consultList from './conponent/consult-list'
  import researchList from './conponent/research-list'
  import mechanismList from './conponent/mechanism-list'
  export default {
    name: 'service-info',
    components:{
      conversionList,
      expertList,
      equipmentList,
      testList,
      softwareList,
      consultList,
      researchList,
      mechanismList
    },
    data () {
      return{
        activeName:'conversion',
        type: '',
        conversion: false,
        expert: false,
        equipment: false,
        test: false,
        software: false,
        consult: false,
        research: false,
        mechanism: false
      }
    },
    created () {
      this.type = this.$route.query.type || ''
      this.$nextTick(() => {
        this.activeName = this.type
        this[this.type] = true
      })
    },
    methods:{
    //  tab页切换
      handleClick(tab, event) {
        this[tab.name] = true
      },
      // 顶部 tab 标签 切换时跳转的网址
      toStandardbzfw() {
        window.open('http://std.samr.gov.cn/')
      },
      toStandardzcfw() {
        window.open('http://gjzwfw.www.gov.cn/col/col644/index.html')
      },
      toStandardqyxycx() {
        window.open('http://www.gsxt.gov.cn/index.html')
      },
      toStandardzwqyml() {
        window.open('http://xwqy.gsxt.gov.cn/')
      },
      toStandardrzrkfw() {
        window.open('http://cx.cnca.cn/CertECloud/index/index/page')
      },
      toStandardsbjs() {
        window.open('https://tm.gongsibao.com/tm/tmdashboard')
      },
      toSCRM() {
        window.open('https://scrm.gongsibao.com/home.html')
      }
    },
  }
</script>

<style scoped>
    .zxw-nav {
        /*background: linear-gradient(90deg, #35bce2, #409eff);*/
        background: white;
        line-height: 2.4rem;
        width: 100%;
        outline: none;
    }

    .zxw-nav .wrap {
        width: 1300px;
        margin: 0 auto;
    }

    .zxw-nav li {
        width: 7rem;
        color: #404040;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
    }

    .zxw-nav li:hover,
    .zxw-nav li.on {
        background: #69c2ff;
        font-size: 15px;
        color: white;
    }
</style>
