<template>
    <div id="conversion-list">
                <el-form :inline="true" :model="dataForm" class="demo-form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="dataForm.queryString" placeholder="请输入关键字检索" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="排序规则">
                        <el-select v-model="dataForm.sortField" placeholder="排序规则" clearable>
                            <el-option :label="item.label" :value="item.value" v-for="(item,index) in sortFieldList" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序方式">
                        <el-select v-model="dataForm.sortValue" placeholder="排序方式" clearable>
                            <el-option :label="item.label" :value="item.value" v-for="(item,index) in sortValueList" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="dataList" border style="width: 100%" v-loading="loading">
                    <el-table-column type="index" label="序号" width="80" align="center" :index="indexMethod"></el-table-column>
                    <el-table-column prop="goodsName" label="服务名称"  align="center"></el-table-column>
                    <el-table-column prop="gcName" label="服务类型" width="180" align="center"></el-table-column>
                    <el-table-column prop="specsName" label="规格" width="180" align="center"></el-table-column>
                    <el-table-column prop="storeName" label="服务商"  align="center"></el-table-column>
                    <el-table-column prop="address" label="地址" width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{scope.row.areaId1 + scope.row.areaId2}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="mt-3 uf uf-ac uf-pe">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
    </div>
</template>

<script>
  export default {
    name: 'conversion-list',
    data () {
      return{
        dataForm:{
          queryString: '',
          sortField: '',
          sortValue: '',
        },
        loading: false,
        data: {},
        dataList: [],
        pageNo: 1,
        pageSize: 10,
        total:10,
        sortFieldList:[
          {
            label:'时间',
            value: '1',
          },
          {
            label:'咨询量',
            value: '2',
          },
          {
            label:'好评率',
            value: '3',
          },
          {
            label:'综合排序',
            value: '4',
          }
        ],
        sortValueList:[
          {
            label:'升序',
            value: '1',
          },
          {
            label:'降序',
            value: '2',
          },
        ]
      }
    },
    created () {
      this.getList()
    },
    methods:{
      //查询
      onSubmit () {
        this.pageNo = 1
        this.getList()
      },
      // 获取技术转化服务列表
      getList () {
        this.loading = true
        this.$http({
          url: '/tecApi/api/search/queryGoodsEsByPageByZXW',
          method: 'get',
          params: this.$http.adornParams({
            gcId: '100861',
            queryString: this.dataForm.queryString,
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            sortField: this.dataForm.sortField,
            sortValue: this.dataForm.sortValue,
          })
        }).then(({data}) => {
          this.loading = false
          if (data && data.code === 200) {
            this.data = data.data || {}
            let pageList = this.data.pageList || {}
            this.dataList = pageList.list || []
            this.total = pageList.total || 0
          }
        })
      },
    //  分页
      handleSizeChange (value) {
        this.pageSize = value
        this.getList()
      },
      handleCurrentChange (value) {
        this.pageNo = value
        this.getList()
      },
      indexMethod (index) {
        return this.pageSize*(this.pageNo-1) + index+1
      },
    },
  }
</script>

<style scoped>

</style>
