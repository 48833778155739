<template>
    <div id="equipment-list">
        <el-form :inline="true" :model="dataForm" class="demo-form-inline">
            <el-form-item label="关键字">
                <el-input v-model="dataForm.keyWords" placeholder="请输入关键字检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="学科">
                <el-select v-model="dataForm.equiPmentClass" placeholder="请选择学科" clearable>
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in equiPmentClassList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border style="width: 100%" v-loading="loading">
            <el-table-column type="index" label="序号" width="80" align="center" :index="indexMethod"></el-table-column>
            <el-table-column prop="name" label="仪器名称" width="" align="center"></el-table-column>
            <el-table-column prop="standard" label="仪器型号" width="180" align="center"></el-table-column>
            <el-table-column prop="department" label="仪器依托单位" width="" align="center"></el-table-column>
            <el-table-column label="仪器所在省市" width="180" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.location + scope.row.ejarea}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-link :href="scope.row.jumpLink" target="_blank" :underline="false" type="primary">详情</el-link>
                    <el-link :href="scope.row.imageUrl" target="_blank" :underline="false" type="primary" class="ml-3">图片查看</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="mt-3 uf uf-ac uf-pe">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'equipment-list',
    data () {
      return{
        dataForm:{
          keyWords: '',
          equiPmentClass: '',
        },
        loading: false,
        data: {},
        dataList: [],
        pageNo: 1,
        pageSize: 10,
        total:10,
        equiPmentClassList:[
          {
            value: '数学',
          }, {
            value: '力学',
          }, {
            value: '化学',
          }, {
            value: '地球科学',
          }, {
            value: '生物学',
          }, {
            value: '林学',
          }, {
            value: '水产学',
          }, {
            value: '基础医学',
          }, {
            value: '临床医学',
          }, {
            value: '药学',
          },
        ]
      }
    },
    created () {
      this.getequipmentList()
    },
    methods:{
      // 查询
      onSubmit () {
        this.pageNo = 1
        this.getequipmentList()
      },
      // 获取仪器设备列表
      getequipmentList () {
        this.loading = true
        this.$http({
          url: '/equipmentApi/api/equipShare/getEquipDetailPageByRedisByZXW',
          method: 'post',
          data: this.$http.adornData({
            equiPmentClass: this.dataForm.equiPmentClass,
            keyWords: this.dataForm.keyWords,
            page: this.pageNo,
            limit: this.pageSize,
          })
        }).then(({data}) => {
          this.loading = false
          if (data && data.code === 200) {
            this.data = data.data || {}
            let pageList = this.data.data || {}
            this.dataList = pageList.data || []
            this.total = this.dataList.length || 0
          }
        })
      },
      equiPmentImg () {},
      //  分页
      handleSizeChange (value) {
        this.pageSize = value
        this.getequipmentList()
      },
      handleCurrentChange (value) {
        this.pageNo = value
        this.getequipmentList()
      },
      indexMethod (index) {
        return this.pageSize*(this.pageNo-1) + index+1
      },
    },
  }
</script>

<style scoped>

</style>
